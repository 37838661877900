/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Image, Subtitle, Divider } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"kontakt"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-jud12m css-42e4bw pb--60 pt--60" name={"yy9ybiiskh"} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/17142/ae0b1a6021bc47638d65cbb5abab3a39_bri=85__s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/17142/ae0b1a6021bc47638d65cbb5abab3a39_bri=85__s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/17142/ae0b1a6021bc47638d65cbb5abab3a39_bri=85__s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/17142/ae0b1a6021bc47638d65cbb5abab3a39_bri=85__s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/17142/ae0b1a6021bc47638d65cbb5abab3a39_bri=85__s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/17142/ae0b1a6021bc47638d65cbb5abab3a39_bri=85__s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/17142/ae0b1a6021bc47638d65cbb5abab3a39_bri=85__s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/17142/ae0b1a6021bc47638d65cbb5abab3a39_bri=85__s=3000x_.jpg);
    }
  
background-position: 50% 50%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1 --full flex--center" columns={"1"} fullscreen={true}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--shadow4 title-box--center fs--48" content={"<br><br><br><br><br><br><br><br><br><br><br><br><br><br><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="js-anim  --anim3" anim={"3"} name={"a9cz69wiudi"} style={{"backgroundColor":"rgba(0, 0, 0, 1)"}} layout={"l2"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3 --full" columns={"3"} fullscreen={true}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/o/i/moduls/email-flat-1.svg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":60}} RootClassName={"column__icon"}>
              </Image>

              <Subtitle className="subtitle-box" content={"<span style=\"font-weight: bold; color: var(--white);\">info@detivakci.org<br></span>"}>
              </Subtitle>

              <Divider >
              </Divider>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/o/i/moduls/phone-flat-1.svg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":60}} RootClassName={"column__icon"}>
              </Image>

              <Subtitle className="subtitle-box" content={"<span style=\"font-weight: bold; color: var(--white);\">+420 735 239 759<br></span>"}>
              </Subtitle>

              <Divider >
              </Divider>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/o/i/moduls/adress-flat-1.svg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":60}} RootClassName={"column__icon"}>
              </Image>

              <Subtitle className="subtitle-box" content={"<span style=\"font-weight: bold; color: var(--white);\">Pernerova 444, Pardubice<br></span>"}>
              </Subtitle>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3 --full" columns={"3"} fullscreen={true}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--white);\"><span style=\"font-weight: bold;\">datová schránka</span><br>ixk8n95</span><br>"}>
              </Subtitle>

              <Divider >
              </Divider>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--white);\"><a href=\"https://www.uol.cz/?gclid=EAIaIQobChMIjJ7c85z_9gIVCIxoCR0fEwjzEAAYASAAEgL7KfD_BwE\" target=\"_blank\" style=\"\"><span style=\"font-weight: bold;\">účetnictví nám vede</span><br>společnost UOL a.s.&nbsp;</a></span><br>"}>
              </Subtitle>

              <Divider >
              </Divider>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--white);\"><span style=\"font-weight: bold;\">IČO</span><br>08985090</span><br>"}>
              </Subtitle>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3 --full" columns={"3"} fullscreen={true}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" content={"<a href=\"https://ib.fio.cz/ib/transparent?a=2101792763\" target=\"_blank\"><span style=\"color: var(--white);\"><span style=\"font-weight: bold;\">transparentní účet</span></span><br><span style=\"color: var(--color-blend--95);\">2101792763/2010</span></a><br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" content={"<a href=\"https://ib.fio.cz/ib/transparent?a=2402063206\" target=\"_blank\"><span style=\"color: rgb(255, 255, 255); font-weight: 700;\">vánoční účet<br></span><span style=\"color: var(--color-blend--95);\">2402063206/2010</span></a><br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" content={"<a href=\"https://drive.google.com/file/d/1CVWzOQuzipumQbibw1WHVfcTClXAjzfH/view?usp=sharing\" target=\"_blank\"><span style=\"color: var(--white);\"><span style=\"font-weight: bold;\">sbírkový účet</span><br></span><span style=\"color: var(--color-blend--95);\">2901795727/2010</span></a><br>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1 --full" columns={"1"} fullscreen={true}>
            
            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}